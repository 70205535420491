import { render, staticRenderFns } from "./singleSlider.vue?vue&type=template&id=a50b0c64&scoped=true&"
import script from "./singleSlider.vue?vue&type=script&lang=js&"
export * from "./singleSlider.vue?vue&type=script&lang=js&"
import style0 from "./singleSlider.vue?vue&type=style&index=0&id=a50b0c64&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a50b0c64",
  null
  
)

export default component.exports