var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('vue2-slider',{ref:"slider",staticStyle:{"padding-left":"0px","padding-right":"0px"},attrs:{"real-time":true,"show":true,"height":10,"clickable":!_vm.processDragable,"processStyle":{'backgroundColor': _vm.colourToUse},"bgStyle":{
            'backgroundColor': '#fff',
            'boxShadow': 'inset 0.5px 0.5px 3px 1px rgba(0,0,0,.1)',
            'border': '0.1px solid #a6a6a6',
            'borderRadius': '12px'
        },"sliderStyle":{
            'backgroundColor': _vm.colourToUse,
            'borderRadius': '12px',
            'width': '24px',
            'height': '24px',
            'top': '-6px',
            'position': 'absolute'
        },"speed":_vm.speed,"min":_vm.minIndex,"max":_vm.maxIndex,"value":_vm.value,"tooltip":_vm.tooltip,"formatter":_vm.tooltipFormatter,"disabled":_vm.disabled,"processDragable":_vm.processDragable},on:{"drag-start":_vm.onDragStart,"drag-end":_vm.onDragEnd,"callback":_vm.changed}}),(_vm.includeStartingPoint)?_c('div',{staticClass:"startingDot",style:([{'left': _vm.startingPoint + 'px'}, {'background': _vm.startPointColour}])}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }