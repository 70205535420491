<template>
    <div v-if="pageAvailable" class="row">
        <div class="col-md-8 framed partialPageCardLeft">
            <div class="card">
                <toggle-header title="Fast-Track dashboard" :tabs="tabs" :viewOverride="currentView" @currentView="onCurrentViewUpdate"/>
                <template v-if="currentView === trafficLights">
                    <div class="LCPcontent">
                        <p>Comparison with LCP’s Fast Track Forecast benchmarks. Please note Fast Track is expected to be relevant for all schemes including those using a Bespoke approach. LCP Fast Track Forecast is our current best estimate of a minimum Fast Track compliant position.</p>
                        <lcp-button :handleClick="openCaveats()" text="Important notes on our Fast Track dashboard assumptions and methodology"/>
                    </div>
                    <div class="LCPcontent" >
                        <table class="table" style="width:100%" v-for="(resultsByCategory, category, rowCount) in tprResults" :key="category">
                            <tr>
                                <td align="left" class="tableRow" style="width: auto">{{category}}</td>
                                <td align="right" class="tableRow" style="width: 70px">{{rowCount == 0 ? 'Rating' : ''}}</td>
                            </tr>
                            <tr v-for="(result, key) in resultsByCategory" :key="key">
                                <td colspan="2">
                                    <div v-if="result.caveat != null" class="LCPcontent caveat">{{result.caveat}}</div>
                                    <lcp-table v-if="getTableData(result) != null" class="noShadow" :config="getTableData(result)" :allowCondense="true" :initialCondensed="true"/>
                                </td>
                            </tr>
                        </table>
                    </div>
                </template>
                <template v-if="currentView === projectionView">
                    <div class="LCPcontent inputs-flex">
                        <div class="col-md-6">
                        <input-wrapper :inputTemplate="inputs[config.assetDropdownId]"/>
                        </div>
                        <div class="col-md-6">
                        <input-wrapper :inputTemplate="inputs[config.fundingDropdownId]"/>
                        </div>
                </div>
                <highchart class="LCPcontent" :options="projectionChartOptions" :series-options="chartSeriesAssetAndLiability" key="projectedAandL"/>
                <div v-if="dynamicOutput.chartLabel != null" class="LCPcontent">{{dynamicOutput.chartLabel}}</div>
                <lcp-table class="LCPcontent" :config="projectionTableData"/>
                <div class="LCPcontent">{{dynamicOutput.tableFootnote}}</div>
                </template>
                <template v-if="currentView === recoveryView">
                    <div style="position: relative">
                        <div v-if="blocked" class="blockedContent">
                            <div class="text">
                                <span class="icon icon-Lock"/>
                                {{blockedContentText}}
                            </div>
                        </div>
                        <highchart class="LCPcontent" :options="recoveryChartOptions" :series-options="chartSeriesRecoveryPlan" key="recoveryPlan"/>
                    </div>
                </template>
            </div>
        </div>
        <div class="col-md-4 framed partialPageCardRight">
            <div class="card">
                <div class="card-header">
                    <h1>{{headerRight}}</h1>
                </div>
                <div class="input-container">
                    <div v-html="subHeaderRight" class="LCPcontent"></div>
                    <div class="col-md-12">
                        <hr style="margin: 10px auto"/>
                    </div>
                    <div class="col-md-12">
                        <input-wrapper :inputTemplate="inputs[config.covenantDropdownId]"/>
                    </div>
                    <div v-if="inputs[config.highRiskDiscountRateId]" class="col-md-12">
                        <input-wrapper :inputTemplate="inputs[config.highRiskDiscountRateId]"/>
                    </div>
                    <div v-if="inputs[config.lowRiskDiscountRateId]" class="col-md-12">
                        <input-wrapper :inputTemplate="inputs[config.lowRiskDiscountRateId]"/>
                    </div>
                    <div v-if="this.inputs[this.config.ldrEndDateId]" class="col-md-12">
                        <input-wrapper :inputTemplate="this.inputs[this.config.ldrEndDateId]" :dynamic-label="dynamicOutput.ldrEndDateLabel"/>
                    </div>
                    <div v-if="showOptions" class="col-md-12">
                        <input-wrapper :inputTemplate="inputs[config.recoveryPlanOptionsId]"/>
                    </div>
                    <div v-if="showCalculationOptions" class="col-md-12">
                        <input-wrapper :inputTemplate="inputs[config.recoveryPlanTargetId]"/>
                    </div>
                    <div v-if="showCalculationOptions && showEditRp && inputs[config.recoveryPlanReturnsId]" class="col-md-12">
                        <input-wrapper :inputTemplate="inputs[config.recoveryPlanReturnsId]"/>
                    </div>
                    <div v-if="showCalculationOptions && showEditRp" class="col-md-12">
                        <input-wrapper :inputTemplate="inputs[config.recoveryPlanSliderId]"/>
                    </div>
                    <div class="col-md-12">
                        <hr style="margin: 10px auto"/>
                    </div>
                    <div class="col-md-12">
                        <div v-if="fileCollection.length > 0" class="assumptionButton">
                            <lcp-button :handleClick="openFileDownloader" text="View supporting documents"/>
                        </div>
                    </div>
                    <div class="input-container">
                        <div v-if="includeProposeButton" :class="buttonClass"><lcp-button class="iconLarge"  :handleClick="propose()" text="Propose this plan" /></div>
                        <div v-if="includeAdvisorButton" :class="buttonClass"><lcp-button class="iconLarge" :handleClick="navigateTo()" text="Speak to an advisor" /></div>
                    </div>
                </div>
            </div>
        </div>
        <download-files-modal v-if="showDownloadManager" :fileCollection="fileCollection"  @close="showDownloadManager = false"/>
    </div>
    <div v-else class="row">
        <div id="mainCard" class="col-md-12 framed">
            <div class="card">
                <div class="card-header">
                    <h1>Fast-Track dashboard</h1>
                </div>
                <vue-markdown class="LCPcontent" :source="pageNotAvailableText"/>
            </div>
        </div>
    </div>
</template>

<script>

import lineChart from '@/components/common/charts/lineChart.js';
import barChart from '@/components/common/charts/barChart.js';
import downloadFilesModal from '@/components/common/uiLayouts/downloadFilesModal.vue';
import table from '@/components/common/tables/table.vue';
import swal from '@/components/common/swalWrapper.js';

const chartOverrides = {
    chart: {
        defaultSeriesType: 'column'
    },
    tooltip: {
        formatter () {
            let s = '<b>' + Math.round(this.x) + '</b><table>';
            s += '<tr style=\'color:' + this.color + '\'><td style=\'padding-right: 10px;\'>' + this.series.name + ': </td><td style=\'text-align: right\'><b>' + this.y.toAmountString() + '</b></td></tr>';
            s += '</table>';
            return s;
        }
    },
    xAxis: [{
        labels: {
            formatter () {
                return Math.round(this.value);
            }
        }
    }]
};

const caveats = '<div class="LCPcontent"><p class="swalTextLeftAlign">LCP Fast Track Dashboard is based on our current best estimate of a minimum Fast Track compliant position under the new Defined Benefit funding code of practice. The new code is not expected to be in force until late 2023, and will only apply to valuation dates after the date that it comes into force. It is therefore not directly applicable to your current valuation. However, The Pensions Regulator (TPR) has indicated that it sees the new code as merely codifying good practice, and so an estimate of a Fast Track compliant position is a sensible starting point when considering a valuation that would be acceptable to TPR.</p>' +
'<p class="swalTextLeftAlign">In order to come up with our best estimate of a minimum Fast Track compliant position, we have used information provided in TPR’s first consultation on the new code, as well as other information provided by TPR at various times. This is known as our LCP Fast Track Forecast.</p>' +
'<p class="swalTextLeftAlign">Our understanding of the specifics of the new funding regime are fast evolving and as such you should consult your LCP adviser(s) before making any decisions based on the results. In particular, much of the detail of the new code including the specific Fast Track parameters will not be known until TPR publishes its new funding code of practice, which is currently expected in late 2022. As such the parameters and structure of LCP Fast Track Forecast are subject to change as more detail on the new funding regime becomes available. If you would like further detail on the new code or any of the tests carried out then please contact the LCP partner who usually advises you.</p>' +
'<p class="swalTextLeftAlign">For schemes using additional support (such as a contingent asset or a parental guarantee) in order to satisfy the new funding requirements, we currently expect you will have to use a Bespoke approach rather than Fast Track. However, you may still be required to consider your approach against Fast Track and so you should still use the LCP Fast Track Dashboard to consider how your scheme compares to the Fast Track requirements.  This will likely also influence the work that is needed to evidence the appropriateness of a Bespoke approach.</p><hr></div>';

export default {
    data () {
        return {
            currentView: null,
            showDownloadManager: false
        };
    },
    components: {
        downloadFilesModal
    },
    created () {
        this.headerRight = 'Refining your plan';
        this.projectionView = 'Projection';
        this.recoveryView = 'Recovery plan';
        this.trafficLights = 'Comparison';

        this.tabs = [];
        this.tabs.push(this.trafficLights);
        this.tabs.push(this.projectionView);
        this.tabs.push(this.recoveryView);

        this.config = this.$staticStore.state.fasttrackdashboard.config;
        this.tpDisplayName = this.config.tpDisplayName;
        this.pageAvailable = this.$staticStore.state.fasttrackdashboard.pageAvailable;
        this.pageNotAvailableText = this.$staticStore.state.fasttrackdashboard.pageNotAvailableText;
        this.inputs = this.$staticStore.state.fasttrackdashboard.inputControls;
        this.schemeId = this.$store.state.scheme.id;
        this.includeProposeButton = this.config.includeProposeButton;
        this.includeAdvisorButton = this.config.includeAdvisorButton;
        this.buttonClass = this.includeProposeButton && this.includeAdvisorButton ? 'col-md-6' : 'col-md-12';
        this.fileCollection = this.config.fileCollection;

        this.projectionChartOptions = lineChart.chartOptions(window.LCP.con.AMOUNT);

        this.recoveryChartOptions = barChart.chartOptions(chartOverrides, window.LCP.con.AMOUNT);
        this.recoveryChartOptions.xAxis[0].title.text = `Year from ${this.config.calculationDate.ToDayAndMonthString()}`;

        this.blockedContentText = `Under the propossed assumptions you have a surplus on the ${this.tpDisplayName} basis and the LCP Fast Track Forecast.`;
    },
    computed: {
        dynamicOutput () {
            return this.$store.state.fasttrackdashboard.dynamicOutput;
        },
        showOptions () {
            return this.dynamicOutput.showOptions;
        },
        showCalculationOptions () {
            return this.dynamicOutput.showCalculationOptions;
        },
        showEditRp () {
            return this.dynamicOutput.showEditRp;
        },
        subHeaderRight () {
            return this.dynamicOutput.recoveryPlanHeaderText;
        },
        blocked () {
            return this.dynamicOutput.blockRecoveryPlan;
        },
        chartSeriesAssetAndLiability () {
            const output = [];

            for (let i = 0; i < this.dynamicOutput.projectionChartSeriesData.length; i++) {
                const chartSeriesDataProjectedXyDate = this.dynamicOutput.projectionChartSeriesData[i];
                output.push(lineChart.getSeriesFromChartSeriesDataProjectedXyDate(chartSeriesDataProjectedXyDate, 'Solid'));
            }

            return output;
        },
        chartSeriesRecoveryPlan () {
            const output = [];

            for (let i = 0; i < this.dynamicOutput.recoveryPlanChartData.length; i++) {
                const chartSeriesDataProjectedXyDouble = this.dynamicOutput.recoveryPlanChartData[i];
                output.push(barChart.getSeriesFromChartSeriesProjectedXyDouble(chartSeriesDataProjectedXyDouble, i));
            }

            return output;
        },
        tprResults () {
            return this.dynamicOutput.tprResults;
        },
        projectionTableData () {
            return this.dynamicOutput.tableData;
        }
    },
    methods: {
        propose () {
            return function () {
                this.$store.dispatch('emailPageToLcp');
            };
        },
        navigateTo () {
            return function () {
                this.$store.dispatch('routeToSchemePage', 'contacts').then(() => {}).catch(() => {});
            };
        },
        openCaveats () {
            return function () {
                swal.showPopup({
                    title: 'Important notes',
                    text: caveats,
                    icon: 'info',
                    width: 700,
                    backdrop: true
                });
            };
        },
        openFileDownloader () {
            this.showDownloadManager = !this.showDownloadManager;
        },
        onCurrentViewUpdate (view) {
            this.currentView = view;
        },
        getTableData (tprResult) {
            if (tprResult.resultText == null) return null;

            const headerRow = this.getRow(tprResult, tprResult.subTests);

            if (!tprResult.subTests) return table.getTableConfig(headerRow, {});

            const subTesRows = {};

            for (let i = 0; i < tprResult.subTests.length; i++) {
                const subTestResult = tprResult.subTests[i];
                subTesRows[i] = this.getRow(subTestResult, false, true);
            }

            const output = table.getTableConfig(headerRow, subTesRows);
            output.includesTotals = true;
            return output;
        },
        getRow (tprResult, dropDown, subTest) {
            const output = [];
            output.push(this.getTextCell(tprResult.resultText, subTest));
            output.push(this.getIconCell(tprResult, dropDown, subTest));

            return output;
        },
        getTextCell (text, subTest) {
            const cell = table.getHeaderCellNoColour(text);

            cell.styleOverrides = {
                width: 'auto',
                'font-weight': 'normal'
            };

            if (subTest) cell.styleOverrides['padding-left'] = '30px';

            if (subTest) cell.styleOverrides.backgroundColor = '#f7f7f7';

            return cell;
        },
        getIconCell (tprResult, dropDown, subTest) {
            const cell = table.getHeaderCellNoColour('');

            cell.iconFormatOverride = true;
            cell.tableCellStyle.iconClass = dropDown ? 'circleArrow' : 'circle';

            cell.iconStyle = {
                backgroundColor: tprResult.ratingColourHex
            };

            cell.styleOverrides = {
                width: '70px'
            };

            if (!tprResult.toolTip) return cell;

            cell.tableCellStyle.tooltipText = this.getTooltip(tprResult.toolTip);
            cell.tableCellStyle.tooltipPlacement = 'right';
            cell.tableCellStyle.tooltipClass = 'byRating';
            if (subTest) cell.styleOverrides.backgroundColor = '#f7f7f7';

            return cell;
        },
        getTooltip (toolTip) {
            let text = '<div style="padding:10px;"><p style=\'color: black\'>' + toolTip.headerText + '</p>';

            text += '<div class="listWrapper" style="color: black">';

            for (let i = 0; i < toolTip.toolTipInfo.length; i++) {
                const element = toolTip.toolTipInfo[i];
                // text += '<div style=\'color:' + element.colourHex + '\'><b>' + element.description + '</b>';
                text += `<div style="display: flex; align-items: center; padding: 5px; margin: 5px -5px; background: #f7f7f7; border-radius: 6px;"><div style="width: 20px; height: 20px; border-radius: 50%; margin-right: 20px;background: ${element.colourHex}; flex-shrink:0"></div> <div class="description" style="font-weight: 500;">${element.description}</div> `;

                // if (element.SubInfo != null) {
                //     text += '<ul>';
                //     for (let i = 0; i < element.SubInfo.length; i++) {
                //         const bullet = element.SubInfo[i];
                //         text += '<li style=\'color:' + element.colourHex + '\'><b>' + bullet + '</b></li>';
                //     }
                //     text += '</ul>';
                // }

                text += '</div>';
            }

            text += '</div></div>';

            return text;
        }
    }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import 'src/assets/scss/base.scss';
@import 'src/assets/scss/colors.scss';
.inputs-flex{
    display: flex;
    .inputMargin{
        min-width: 300px;
    }
}
.noShadow {
    :deep(.table) {
        :deep(.tr) {
            box-shadow: none;
        }
    }
}
.caveat{
    font-weight: bold;
}

.tableRow {
    padding: 10px 10px;
    font-weight: bold;
    background: #eee;
    &:first-of-type{
            border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    }
    &:last-of-type{
            border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    }
}

.assumptionButton{
    margin-left: auto;
    margin-right: auto;
}
.input-container{
   padding-top: 0;
}

.blockedContent {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: lightgrey;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 6px 6px;
    .text {
        font-size: 20px;
        padding: 30px;
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        span {
            display: block;
            font-size: 60px;
            text-align: center;
        }
        @include responsive ('sm') {
            font-size: $fontSizeStandard;
        }
    }
}

</style>
