import { render, staticRenderFns } from "./inputComponent.vue?vue&type=template&id=5c1b0415&scoped=true&"
import script from "./inputComponent.vue?vue&type=script&lang=js&"
export * from "./inputComponent.vue?vue&type=script&lang=js&"
import style0 from "./inputComponent.vue?vue&type=style&index=0&id=5c1b0415&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c1b0415",
  null
  
)

export default component.exports