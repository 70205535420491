<template>
    <div class="modalBg">
        <div class="editModal">
            <div class="modalHeader">
                <div class="closeModal" @click="$emit('close')"><span class="icon icon-x-circle"/></div>Download files
            </div>
            <div class="LCPcontent">
                <lcp-table class="LCPcontent" :config="tableData"/>
            </div>
        </div>
    </div>
</template>

<script>

import table from '@/components/common/tables/table.vue';

export default {
    props: {
        fileCollection: Array
    },
    created () {
        this.tableData = this.getTableData();
    },
    methods: {
        downloadReport (id) {
            this.$store.dispatch('getReport', { reportType: id, pageState: 'downloadingReport' }).then((response) => {}).catch(() => {});
        },
        getTableData () {
            const headerRow = [];
            headerRow.push(table.getCellDefinition('File name'));

            const cellContentByRow = {};

            for (let i = 0; i < this.fileCollection.length; i++) {
                const row = [];

                const file = this.fileCollection[i];

                const cellsForRow = table.getCellDefinition(file);
                cellsForRow.tableCellStyle.iconClass = 'icon-download-cloud';

                const downloadReport = this.downloadReport;

                cellsForRow.tableCellStyle.clicked = function () {
                    downloadReport(file);
                };

                row.push(cellsForRow);
                cellContentByRow[i] = row;
            }

            return table.getTableConfig(headerRow, cellContentByRow);
        }
    }
};

</script>

<style lang="scss" scoped>

.modalBg{
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,.4);
    z-index: 10;
}

.editModal{
    position: absolute;
    width: 500px;
    max-height: 75%;
    overflow: auto;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    color: black;
    box-shadow: 0 9px 23px rgba(0, 0, 0, 0.09), 0 5px 5px rgba(0, 0, 0, 0.06) !important;
    border-radius: 6px;
    padding: 10px;
    z-index: 3;
    .modalHeader{
        position: relative;
        margin-bottom:10px;
        font-size: 16px;
        padding: 0 8px;
        .closeModal{
            float: right;
            font-size: 20px;
            cursor: pointer;
        }
    }
}

</style>
