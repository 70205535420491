import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '../router/index.js';
import localStorageManager from './localStorageManager.js';
import fileSave from 'file-saver';
import swal from '@/components/common/swalWrapper.js';

import staticStore from '@/staticStore';

import terms from '@/components/pages/legal/termsWording.vue';
const auth = () => import('@/services/authentication').then(m => m.auth);
Vue.use(staticStore);
Vue.use(Vuex);
const termsUpdated = Math.round(terms.getTermsDate().getTime() / 1000);

export const HOME = 'Home';
export const SESSIONLOADING = 'loadingSession';
export const SCHEMELOADING = 'loadingScheme';
export const PAGELOADING = 'loadingPage';
export const READY = 'ready';
export const ERROR = 'error';
export const INFO = 'info';
export const DATALOADING = 'loadingData';
export const DATALOADED = 'loadedData';
export const DATARESET = 'resetData';

function getPageRoute (scheme, pageRequest, schemeOverrides) {
    let pageRoute = pageRequest.pageName ? pageRequest.pageName : pageRequest;
    if (pageRequest.pageType) pageRoute += '/' + pageRequest.pageType;

    const schemeId = schemeOverrides && schemeOverrides.id ? schemeOverrides.id : scheme.id;
    const fileSystem = schemeOverrides && schemeOverrides.fileSystem ? schemeOverrides.fileSystem : scheme.fileSystem;
    const calculationDate = schemeOverrides && schemeOverrides.calculationDate ? schemeOverrides.calculationDate : scheme.calculationDate;

    const pushPath = `/${schemeId.ToUrlSafeString()}/${fileSystem}/${calculationDate.ToUrlSafeDateString()}/${pageRoute}`;
    return pushPath;
}

export default new Vuex.Store({
    modules: {
        dashboard: {
            namespaced: true,
            state: {
                dynamicOutput: {},
                editDashboardOpen: false
            }
        },
        sensitivities: {
            namespaced: true,
            state: {
                dynamicOutput: {},
                selectedScenario: null
            }
        },
        recoveryplan: {
            namespaced: true,
            state: {
                dynamicOutput: {},
                selectedTab: null
            }
        },
        investmentstrategy: {
            namespaced: true,
            state: {
                dynamicOutput: {},
                selectedTab: null
            }
        },
        fasttrackdashboard: {
            namespaced: true,
            state: {
                dynamicOutput: {}
            }
        },
        triggers: {
            namespaced: true,
            state: {
                dynamicOutput: {}
            }
        },
        marketdata: {
            namespaced: true,
            state: {
                dynamicOutput: {}
            }
        },
        derisking: {
            namespaced: true,
            state: {
                dynamicOutput: {},
                selectedTableLocks: {},
                selectedTab: null,
                selectedTabConsolidated: null
            }
        },
        historyandexperience: {
            namespaced: true,
            state: {
                dynamicOutput: {},
                selectedTableLocks: {}
            }
        },
        accounting: {
            namespaced: true,
            state: {
                dynamicOutput: {}
            }
        },
        covenantmonitoring: {
            namespaced: true,
            state: {
                dynamicOutput: {}
            }
        },
        recoveryrequirementsbyscheme: {
            namespaced: true,
            state: {
                dynamicOutput: {}
            }
        },
        resultssummary: {
            namespaced: true,
            state: {
                selectedTab: null
            }
        }
    },
    state: {
        lastRequestedCancellationToken: null,
        authObject: null,
        storedQuery: null,
        pageState: READY,
        dataState: DATALOADED,
        fullScreen: false,
        toggleMenu: false,
        session: {
            sessionId: null,
            sessionStartTime: null,
            user: {
                emailAddress: null,
                isLcp: null,
                loadedFileSystemTypes: null,
                availableSchemes: null,
                store: null
            },
            pageName: null,
            pageType: null
        },
        scheme: {
            id: null,
            fileSystem: null,
            name: null,
            headerMessages: [],
            calculationDate: null,
            minCalcDateAllowed: null,
            maxCalcDateAllowed: null,
            visualiseAiConfig: null
        }
    },
    getters: {
        termsUpToDate: (state) => state.session.user.store === null || state.session.user.store.termsAgreedDate > termsUpdated,
        getAuthState: (state) => state.authObject,
        getStoredQuery: (state) => state.storedQuery,
        getisLcpLogin: (state) => state.session.user.isLcp,
        getSessionRequest: (state) => () => {
            return {
                sessionId: state.session.sessionId
            };
        },
        getLiveSchemeDictionary: (state) => {
            const keyedOutput = [];

            for (const schemeId in state.session.user.availableSchemes) {
                const schemeData = state.session.user.availableSchemes[schemeId].availabilityByFileSystem.live;
                if (schemeData) keyedOutput[schemeData.displayName + '_' + schemeId] = { id: schemeId, name: schemeData.displayName };
            }

            return keyedOutput;
        },
        getSchemeRequest: (state, getters) => (reqInputOverrides) => {
            const existingSessionRequest = getters.getSessionRequest();

            return window.LCP.fn.extend(existingSessionRequest, {
                fileSystem: reqInputOverrides && reqInputOverrides.fileSystem ? reqInputOverrides.fileSystem : state.scheme.fileSystem,
                schemeId: reqInputOverrides && reqInputOverrides.schemeId ? reqInputOverrides.schemeId : state.scheme.id,
                calculationDate: reqInputOverrides && reqInputOverrides.calculationDate ? reqInputOverrides.calculationDate : state.scheme.calculationDate
            });
        },
        getPageRequest: (state, getters) => (reqInputOverrides) => {
            const schemeRequest = getters.getSchemeRequest();

            return window.LCP.fn.extend(schemeRequest, {
                pageName: reqInputOverrides ? reqInputOverrides.pageName : state.session.pageName,
                pageType: reqInputOverrides ? reqInputOverrides.pageType : state.session.pageType,
                pageParams: reqInputOverrides?.pageParams
            });
        },
        getPageUpdateRequest: (state, getters) => () => {
            const pageRequest = getters.getPageRequest();
            const inputControlsForPage = staticStore.state[pageRequest.pageName].inputControls;

            const inputValues = {};
            const inputLocks = {};

            for (const i in inputControlsForPage) {
                const inputControl = inputControlsForPage[i];
                inputValues[i] = inputControl.value;
                inputLocks[i] = inputControl.locked !== undefined ? inputControl.locked : false;
            }

            return window.LCP.fn.extend(pageRequest, {
                currentInputValues: inputValues,
                currentInputLocks: inputLocks
            });
        },
        getReportRequest: (state, getters) => (reqInputs) => {
            const pageRequest = getters.getPageRequest();

            return window.LCP.fn.extend(pageRequest, {
                reportType: reqInputs.reportType
            });
        },
        getHeaderToRemoveRequest: (state, getters) => (messageId) => {
            const schemeRequest = getters.getSchemeRequest();

            return window.LCP.fn.extend(schemeRequest, {
                messageId: messageId
            });
        },
        getUpdateUserStoreRequest: (state, getters) => () => {
            const sessionRequest = getters.getSessionRequest();

            return window.LCP.fn.extend(sessionRequest, {
                store: state.session.user.store
            });
        },
        getUserStatsRequest: (state, getters) => (schemeId) => {
            const sessionRequest = getters.getSessionRequest();
            return window.LCP.fn.extend(sessionRequest, {
                schemeId
            });
        },
        getUpdateSchemeEmailSettingsRequest: (state, getters) => (schemeEmailSettings) => {
            const sessionRequest = getters.getUserStatsRequest(schemeEmailSettings.schemeId);
            return window.LCP.fn.extend(sessionRequest, {
                emailEnabled: schemeEmailSettings.emailEnabled,
                replyEmailAddress: schemeEmailSettings.replyEmailAddress
            });
        },
        getUpdateMonthlyEmailFlagRequest: (state, getters) => (userEmailSettings) => {
            const sessionRequest = getters.getUserStatsRequest(userEmailSettings.schemeId);
            return window.LCP.fn.extend(sessionRequest, {
                userEmailAddress: userEmailSettings.userEmailAddress,
                receiveMonthlyEmail: userEmailSettings.receiveMonthlyEmail
            });
        },
        getEmailPageToLcpRequest: (state, getters) => () => {
            const request = getters.getSchemeRequest();

            const pageLink = getters.getPageQueryString;

            return window.LCP.fn.extend(request, {
                pageLink: pageLink
            });
        },
        getPageQueryString: (state) => {
            let currentRoute = window.location.href;

            const pageName = state.session.pageName;
            const staticStoreForPage = staticStore.state[pageName];
            if (staticStoreForPage == null) return currentRoute;

            const inputControls = staticStoreForPage.inputControls;
            if (inputControls == null) return currentRoute;

            let count = 1;
            const inputControlsCount = Object.keys(inputControls).length;
            currentRoute += '?';
            for (const i in inputControls) {
                const currentValue = inputControls[i];
                currentRoute += `${i}=${currentValue.value}`;
                if (count !== inputControlsCount) currentRoute += '&';
                count++;
            }

            return currentRoute;
        }
    },
    mutations: {
        toggleMenu (state, val) {
            if (val == null) {
                state.toggleMenu = !state.toggleMenu;
            } else {
                state.toggleMenu = val;
            }
        },
        setStoredQuery (state, query) {
            state.storedQuery = query;
        },
        setAuthState (state, auth) {
            state.authObject = auth;
        },
        setReady (state) {
            state.pageState = READY;
        },
        setLoadingSession (state) {
            state.pageState = SESSIONLOADING;
        },
        setLoadingScheme (state, requestedSchemeInfo) {
            state.pageState = SCHEMELOADING;
            state.scheme.id = requestedSchemeInfo.schemeId;

            const dataForScheme = state.session.user.availableSchemes[requestedSchemeInfo.schemeId];
            if (dataForScheme != null) {
                const dataForFileSystem = dataForScheme.availabilityByFileSystem[requestedSchemeInfo.fileSystem];
                if (dataForFileSystem != null) {
                    state.scheme.name = dataForFileSystem.displayName;
                    return;
                }
            }

            state.scheme.name = null;
        },
        setLoadingPage (state) {
            state.pageState = PAGELOADING;
        },
        setPageNameAndType (state, data) {
            state.session.pageName = data.pageName;
            state.session.pageType = data.pageType;
        },
        uploadToLive (state) {
            state.pageState = 'Queuing setup for live update';
        },
        setDataReset (state) {
            state.dataState = DATARESET;

            /* This is for controls that aren't currently rendered - all rendered controls will go through UpdateInput */
            const pageName = state.session.pageName;
            const inputControlsForPage = staticStore.state[pageName].inputControls;

            for (const j in inputControlsForPage) {
                const control = inputControlsForPage[j];
                if (control.resetValue == null) continue;

                control.value = control.resetValue;
                control.locked = control.resetLocked;
            }
        },
        setLoadingUpdate (state) {
            state.dataState = DATALOADING;
        },
        setDataLoaded (state) {
            state.dataState = DATALOADED;
        },
        setDataState (state, value) {
            state.dataState = value;
        },
        wipeSchemeInfo (state) {
            state.investmentstrategy.selectedTab = null;
            state.recoveryplan.selectedTab = null;
            state.resultssummary.selectedTab = null;
            state.derisking.selectedTab = null;
            state.derisking.selectedTabConsolidated = null;
            state.derisking.selectedTableLocks = {};
            state.historyandexperience.selectedTableLocks = {};

            state.scheme = {
                id: null,
                fileSystem: null,
                name: null,
                headerMessages: [],
                calculationDate: null,
                minCalcDateAllowed: null,
                maxCalcDateAllowed: null,
                visualiseAiConfig: null
            };
        },
        loadSession (state, responseData) {
            state.session.sessionId = responseData.sessionId;
            state.session.sessionStartTime = responseData.sessionStartTime;
            state.session.pageName = null;
            state.session.pageType = null;

            state.session.user.emailAddress = responseData.user.emailAddress;
            state.session.user.isLcp = responseData.user.isLcp;
            state.session.user.loadedFileSystemTypes = responseData.user.loadedFileSystemTypes;
            state.session.user.availableSchemes = responseData.user.availableSchemes;
            state.session.user.store = responseData.user.store;

            localStorageManager.storeSessionId(state.session.sessionId);

            state.scheme = {
                id: null,
                fileSystem: null,
                name: null,
                headerMessages: [],
                calculationDate: null,
                minCalcDateAllowed: null,
                maxCalcDateAllowed: null,
                visualiseAiConfig: null
            };

            for (const scheme in state.session.user.availableSchemes) {
                if (state.session.user.store.preferences.byScheme[scheme] != null) continue;

                Vue.set(state.session.user.store.preferences.byScheme, scheme, {
                    visible: true,
                    dashboard: null
                });
            }

            const schemePreferenceKeys = Object.keys(state.session.user.store.preferences.byScheme);

            for (let i = 0; i < schemePreferenceKeys.length; i++) {
                const schemeId = schemePreferenceKeys[i];
                if (state.session.user.availableSchemes[schemeId] == null) delete state.session.user.store.preferences.byScheme[schemeId];
            }
        },
        loadScheme (state, responseData) {
            state.scheme.id = responseData.id;
            state.scheme.fileSystem = responseData.fileSystem;
            state.scheme.name = responseData.name;
            state.scheme.headerMessages = responseData.headerMessages;
            state.scheme.calculationDate = responseData.calculationDate;
            state.scheme.minCalcDateAllowed = responseData.minCalcDateAllowed;
            state.scheme.maxCalcDateAllowed = responseData.maxCalcDateAllowed;
            state.scheme.visualiseAiConfig = responseData.visualiseAiConfig;

            window.LCP.fn.setFormattingOptions(responseData.formatting);
        },
        loadPage (state, pageData) {
            const pageName = state.session.pageName;

            if (pageData.inputControls && pageData.updateData && pageData.updateData.dynamicOutputsByInputId) {
                for (const i in pageData.inputControls) {
                    pageData.inputControls[i].outputs = pageData.updateData.dynamicOutputsByInputId[i];
                }
            }

            if (staticStore.state[pageName] === undefined) staticStore.state[pageName] = {};
            staticStore.state[pageName].pageAvailable = pageData.pageAvailable;
            staticStore.state[pageName].pageNotAvailableText = pageData.pageNotAvailableText;
            staticStore.state[pageName].config = pageData.config;
            staticStore.state[pageName].inputControls = pageData.inputControls;
            staticStore.state[pageName].caveatSettings = pageData.caveatSettings;

            if (state[pageName] != null) {
                state[pageName].dynamicOutput = pageData.updateData ? pageData.updateData.dynamicOutput : null;
            }

            // clear store
            for (const page in staticStore.state) {
                if (page === pageName) continue;

                staticStore.state[page] = {};
                if (state[page] != null && state[page].dynamicOutput !== undefined) state[page].dynamicOutput = {};
            }
        },
        updatePage (state, responseData) {
            const pageName = state.session.pageName;

            const inputControlsForPage = staticStore.state[pageName].inputControls;

            if (responseData.newControls) {
                for (const i in responseData.newControls) {
                    inputControlsForPage[i] = responseData.newControls[i];
                    inputControlsForPage[i].updated = true;
                }

                for (const s in inputControlsForPage) {
                    if (inputControlsForPage[s].updated) inputControlsForPage[s].updated = false;
                    else delete inputControlsForPage[s];
                }
            }

            if (responseData.dynamicOutputsByInputId) {
                for (const j in inputControlsForPage) {
                    inputControlsForPage[j].outputs = responseData.dynamicOutputsByInputId[j];
                }
            }

            state[pageName].dynamicOutput = responseData.dynamicOutput;
        },
        updateInput (state, data) {
            const pageName = state.session.pageName;
            const staticStoreForPage = staticStore.state[pageName];
            if (staticStoreForPage == null) return;

            const inputControls = staticStoreForPage.inputControls;
            if (inputControls == null) return;

            const inputData = inputControls[data.id];
            if (inputData == null) return;

            inputData.value = data.value;
            inputData.locked = data.locked ? data.locked : false;

            if (inputData.outputs == null || data.index == null) return;

            const dataForIndex = inputData.outputs[data.index];
            if (dataForIndex == null) return;

            state[pageName].dynamicOutput = dataForIndex;
        },
        updateInputValues (state, data) {
            const pageName = state.session.pageName;
            const staticStoreForPage = staticStore.state[pageName];
            if (staticStoreForPage == null) return;

            const inputControls = staticStoreForPage.inputControls;
            if (inputControls == null) return;

            for (const j in inputControls) {
                inputControls[j].value = data.currentInputValues[j];
                inputControls[j].locked = data.currentInputLocks[j];
            }
        },
        updateDynamicOutput (state, data) {
            const pageName = state.session.pageName;
            state[pageName].dynamicOutput = data;

            /* This is precautionary because off the async nature of the call update.
            Inputs updates based on reactivity and may not have executed by the time updatePageData is called. */
            if (data.inputValues == null) return;

            for (const n in data.inputValues) {
                staticStore.state[pageName].inputControls[n].value = data.inputValues[n];
            }
        },
        updateTableLockArray (state, key) {
            const pageName = state.session.pageName;
            Vue.set(state[pageName].selectedTableLocks, key, !state[pageName].selectedTableLocks[key]);
        },
        toggleFullScreen (state) {
            state.fullScreen = !state.fullScreen;
        },
        updateDate (state, date) {
            state.scheme.calculationDate = date;
        },
        updateUserStore (state, store) {
            state.session.user.store = store;
        },
        updateTermsAgreed (state) {
            state.session.user.store.termsAgreedDate = Math.round((new Date()).getTime() / 1000);
        },
        updateSchemeState (state, request) {
            if (request.multi) {
                for (const scheme in state.session.user.store.preferences.byScheme) {
                    state.session.user.store.preferences.byScheme[scheme].visible = request.state;
                }
            } else {
                state.session.user.store.preferences.byScheme[request.scheme.id].visible = request.scheme.visible;
            };
        },
        updateDashboardPreferences (state, dashboardPreferences) {
            state.session.user.store.preferences.byScheme[state.scheme.id].dashboard = dashboardPreferences;
        },
        clearSessionId (state) {
            state.session.sessionId = null;
        },
        editDashboardOpen (state) {
            state.dashboard.editDashboardOpen = true;
        },
        updateHeaderMessages (state, headerMessages) {
            state.scheme.headerMessages = headerMessages;
        }
    },
    actions: {
        updateDate ({ commit, dispatch, state }, date) {
            if (state.scheme.calculationDate === date) return;

            commit('updateDate', date);
            dispatch('routeToSchemePage', { pageName: state.session.pageName, pageType: state.session.pageType });
        },
        routeToSchemePage ({ commit, state }, pageRequest) {
            return new Promise((resolve) => {
                const route = getPageRoute(state.scheme, pageRequest);
                if (route !== router.currentRoute.fullPath) router.push(route);

                resolve();
            });
        },
        routeToScheme ({ commit }, { scheme, fileSystem }) {
            return new Promise((resolve) => {
                const route = `/${scheme.ToUrlSafeString()}/${fileSystem}/null/dashboard`;
                if (route !== router.currentRoute.fullPath) router.push(route);

                resolve();
            });
        },
        loadSession ({ state, commit }) {
            const storedSessionId = localStorageManager.getStoredSessionId();
            const sessionRequest = {
                sessionId: storedSessionId
            };

            if (storedSessionId && storedSessionId === state.session.sessionId) {
                return new Promise((resolve, reject) => {
                    axios.post(process.env.VUE_APP_APIBASE_URL + '/api/onOpenPreLoadedSession', sessionRequest).then(() => {
                        resolve();
                    }).catch((error) => {
                        reject(error);
                    });
                });
            };

            const requestId = storedSessionId ? 'getSession' : 'loadSession';

            commit('setLoadingSession');

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/' + requestId, sessionRequest).then((response) => {
                    commit('loadSession', response.data);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        logoutSession ({ getters }) {
            const existingSession = getters.getSessionRequest();

            return new Promise((resolve) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/logoutSession', existingSession).then(() => {
                    resolve();
                });
            });
        },
        loadScheme ({ commit, getters }, schemeRequestParams) {
            commit('setLoadingScheme', schemeRequestParams);

            const req = getters.getSchemeRequest(schemeRequestParams);

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/loadScheme', req).then((response) => {
                    commit('loadScheme', response.data);
                    resolve();
                }).catch((error) => {
                    reject(error);
                });
            });
        },
        async loadInternalSessionData ({ state, commit, getters, dispatch }) {
            if (state.session.sessionId === null) {
                await dispatch('loadSession').then(() => { }).catch((error) => {
                    return Promise.reject(error);
                });
            }

            commit('setLoadingSession');

            const req = getters.getSessionRequest();

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/loadInternalSessionData', req).then((response) => {
                    commit('loadSession', response.data);
                    resolve();
                }).catch((error) => {
                    dispatch('handleError', { error, reject, resolve, pageRequest: null, preStoreActions: null, redispatchAction: 'loadInternalSessionData', redispatchRequest: req, initialDispatchOverride: 'loadSession' });
                });
            });
        },
        async loadPage ({ state, commit, dispatch, getters }, request) {
            if (state.session.sessionId === null) {
                await dispatch('loadSession').then(() => { }).catch((error) => {
                    return Promise.reject(error);
                });
            }

            for (const schemeId in state.session.user.availableSchemes) {
                if (schemeId === request.schemeId || schemeId.ToUrlSafeString() === request.schemeId) {
                    request.schemeId = schemeId;
                }
            }

            if (state.scheme.id !== request.schemeId || state.scheme.fileSystem !== request.fileSystem) {
                const schemeRequest = getters.getSchemeRequest({ schemeId: request.schemeId, fileSystem: request.fileSystem });
                await dispatch('loadScheme', schemeRequest).then(() => { }).catch((error) => {
                    return Promise.reject(error);
                });
            }

            if (request.pageName === 'privacy' || request.pageName === 'terms') {
                return Promise.resolve();
            }

            const maxDate = state.scheme.maxCalcDateAllowed;
            const minDate = state.scheme.minCalcDateAllowed;

            let dateToUse = request.calculationDate == null ? state.scheme.calculationDate : request.calculationDate;
            if (new Date(dateToUse) > new Date(maxDate)) dateToUse = maxDate;
            else if (new Date(dateToUse) < new Date(minDate)) dateToUse = minDate;

            if (state.scheme.calculationDate !== dateToUse || request.calculationDate !== dateToUse) {
                commit('updateDate', dateToUse);
                return dispatch('routeToSchemePage', { pageName: request.pageName, pageType: request.pageType });
            }

            if (request.pageName === 'default') {
                return dispatch('routeToSchemePage', { pageName: 'dashboard' });
            };

            if (state.pageState !== SCHEMELOADING) commit('setLoadingPage');

            const req = getters.getPageRequest({ pageName: request.pageName, pageType: request.pageType, pageParams: request.pageParams });

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/page', req).then((response) => {
                    commit('setPageNameAndType', { pageName: req.pageName, pageType: req.pageType });
                    commit('loadPage', response.data);
                    commit('setDataLoaded');
                    resolve();
                }).catch((error) => {
                    /* To consider */
                    dispatch('handleError', { error, reject, resolve, pageRequest: req, preStoreActions: null, redispatchAction: null, redispatchRequest: null });
                });
            });
        },
        async updatePageData ({ commit, getters, dispatch }) {
            await Vue.nextTick();

            commit('setLoadingUpdate');

            const req = getters.getPageUpdateRequest();

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/page/update', req).then((response) => {
                    commit('updatePage', response.data);
                    commit('setReady');
                    commit('setDataLoaded');
                    resolve();
                }).catch((error) => {
                    dispatch('handleError', { error, reject, resolve, pageRequest: req, preStoreActions: () => commit('updateInputValues', req), redispatchAction: 'updatePageData', redispatchRequest: null });
                });
            });
        },
        async resetPageData ({ commit, dispatch }) {
            commit('setDataReset');
            await dispatch('updatePageData');
        },
        async updateDynamicOutput ({ commit, dispatch }, data) {
            commit('updateDynamicOutput', data);
            await dispatch('updatePageData');
        },
        getReport ({ state, commit, getters, dispatch }, reqInputs) {
            if (state.pageState !== SCHEMELOADING) state.pageState = reqInputs.pageState;

            const req = getters.getReportRequest(reqInputs);

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/report', req).then((response) => {
                    commit('setReady');
                    const blob = response.data.data.base64ToBlob(response.data.mimeType);
                    fileSave.saveAs(blob, reqInputs.reportType + '_' + response.data.dateAndTime + response.data.extension);
                    swal.showPopup({ icon: 'success', text: 'Report has been successfully downloaded' });
                    resolve(response);
                }).catch((error) => {
                    dispatch('handleError', { error, reject, resolve, pageRequest: req, preStoreActions: null, redispatchAction: 'getReport', redispatchRequest: req });
                });
            });
        },
        updateTermsAgreed ({ commit, dispatch }) {
            commit('updateTermsAgreed');
            dispatch('updateUserStore');
        },
        toggleSchemes ({ commit, dispatch }, request) {
            commit('updateSchemeState', request);
            dispatch('updateUserStore');
        },
        updateDashboardPreferences ({ commit, dispatch }, dashboardPreferences) {
            commit('updateDashboardPreferences', dashboardPreferences);
            dispatch('updateUserStore');
        },
        updateUserStore ({ state, getters, commit, dispatch }) {
            const req = getters.getUpdateUserStoreRequest();

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/updateUserStore', req).then((response) => {
                    resolve();
                }).catch((error) => {
                    const inScheme = state.scheme.name != null;
                    const lcpUser = state.session.user.isLcp;
                    const initialDispatchOverride = inScheme ? null : (lcpUser ? 'loadInternalSessionData' : 'loadSession');
                    dispatch('handleError', { error, reject, resolve, pageRequest: getters.getPageRequest(), preStoreActions: () => commit('updateUserStore', req.store), redispatchAction: 'updateUserStore', redispatchRequest: null, initialDispatchOverride: initialDispatchOverride });
                });
            });
        },
        updateUserMonthlyEmailFlag ({ getters, dispatch }, userEmailSettings) {
            const req = getters.getUpdateMonthlyEmailFlagRequest(userEmailSettings);

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/emailFlags', req).then((response) => {
                    resolve();
                }).catch((error) => {
                    dispatch('handleError', { error, reject, resolve, pageRequest: null, preStoreActions: null, redispatchAction: 'updateUserMonthlyEmailFlag', redispatchRequest: userEmailSettings, initialDispatchOverride: 'loadInternalSessionData' });
                });
            });
        },
        updateSchemeEmailSettings ({ getters, dispatch }, schemeEmailSettings) {
            const req = getters.getUpdateSchemeEmailSettingsRequest(schemeEmailSettings);

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/schemeEmail', req).then((response) => {
                    resolve();
                }).catch((error) => {
                    dispatch('handleError', { error, reject, resolve, pageRequest: null, preStoreActions: null, redispatchAction: 'updateSchemeEmailSettings', redispatchRequest: schemeEmailSettings, initialDispatchOverride: 'loadInternalSessionData' });
                });
            });
        },
        getUserStats ({ getters, dispatch }, schemeIdParam) {
            const req = getters.getUserStatsRequest(schemeIdParam);

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/userStats', req).then((response) => {
                    resolve(response);
                }).catch((error) => {
                    dispatch('handleError', { error, reject, resolve, pageRequest: null, preStoreActions: null, redispatchAction: 'getUserStats', redispatchRequest: schemeIdParam, initialDispatchOverride: 'loadInternalSessionData' });
                });
            });
        },
        handleError ({ dispatch, commit }, { error, reject, resolve, pageRequest, preStoreActions, redispatchAction, redispatchRequest, initialDispatchOverride }) {
            if (error.response && error.response.data === 'UserSessionNotFound') {
                localStorageManager.clearSession();
                dispatch(initialDispatchOverride || 'loadPage', pageRequest).then(() => {
                    if (preStoreActions) preStoreActions();
                    if (redispatchAction) {
                        dispatch(redispatchAction, redispatchRequest).then(() => {
                            commit('setReady');
                            resolve();
                        });
                    } else {
                        commit('setReady');
                        resolve();
                    }
                });
            } else if (error.response && error.response.data === 'SchemePageUnavailable') {
                return dispatch('routeToSchemePage', { pageName: 'dashboard' });
            } else if (error === 'CancelledToken') {
                resolve();
            } else {
                reject(error);
                router.push('/500');
            }
        },
        clearAuthorisation ({ commit }) {
            localStorageManager.clear();
            commit('setAuthState', null);
        },
        signout ({ getters }) {
            auth().then(a => {
                if (getters.getisLcpLogin) {
                    a.initLcp();
                } else {
                    a.initLocal();
                }
                a.signOut();
            });
        },
        dismissHeaderMessage ({ commit, dispatch, getters }, messageId) {
            /* This action is selected via the API so check API if you try and delete */

            const req = getters.getHeaderToRemoveRequest(messageId);

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/removeHeaderMessage', req).then((response) => {
                    commit('updateHeaderMessages', response.data);
                    resolve();
                }).catch((error) => {
                    dispatch('handleError', { error, reject, resolve, pageRequest: getters.getPageRequest(), preStoreActions: null, redispatchAction: null, redispatchRequest: null });
                });
            });
        },
        uploadToLive ({ state, commit, dispatch, getters }, messageId) {
            /* This action is selected via the API so check API if you try and delete */

            commit('uploadToLive');

            const req = getters.getSchemeRequest();

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/uploadToLive', req).then(() => {
                    swal.showPopup({ icon: 'success', text: 'Setup file updates have been queued to go live. The client team should receive a confirmation email shortly. Please contact visualisesupport@lcp.uk.com if you have any problems. Your queued scheme will now be loaded for you.' }, () => {
                        const route = getPageRoute(state.scheme, state.session, { fileSystem: 'queued' });
                        commit('wipeSchemeInfo');
                        window.location = route;
                    });
                    resolve();
                }).catch((error) => {
                    dispatch('handleError', { error, reject, resolve, pageRequest: getters.getPageRequest(), preStoreActions: null, redispatchAction: null, redispatchRequest: null });
                });
            });
        },
        refreshScheme ({ dispatch, getters }, messageId) {
            /* This action is selected via the API so check API if you try and delete */

            const req = getters.getSchemeRequest();

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/refreshScheme', req).then(() => {
                    router.go();
                    resolve();
                }).catch((error) => {
                    dispatch('handleError', { error, reject, resolve, pageRequest: getters.getPageRequest(), preStoreActions: null, redispatchAction: null, redispatchRequest: null });
                });
            });
        },
        emailPageToLcp ({ dispatch, getters }) {
            const req = getters.getEmailPageToLcpRequest();

            return new Promise((resolve, reject) => {
                axios.post(process.env.VUE_APP_APIBASE_URL + '/api/emailPageToLcp', req).then((response) => {
                    swal.showPopup({ icon: 'success', text: 'Email has successfully been sent to the client team. Someone should be in contact with you as soon as possible.' });
                    resolve();
                }).catch((error) => {
                    dispatch('handleError', { error, reject, resolve, pageRequest: getters.getPageRequest(), preStoreActions: null, redispatchAction: null, redispatchRequest: null });
                });
            });
        }
    }
});
